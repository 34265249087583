.user-info {
    position: absolute;
    top: 0;
    right: 0;
}

.user-info:hover {
    background-color: rgb(0, 21, 41) !important;
}

.user-info-button {
    color: #FFFFFF !important;
    background-color: transparent !important;
    border: none !important;
}

.page-container {
    max-width: 800px;
    margin: auto;
}

.site-layout {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
}

.ant-table {
    overflow-x: scroll;
    background-color: white;
}

.ant-layout-header {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
}

.ant-modal {
    max-width: 800px !important;
    width: 100vw !important;
}

.no-pad-left {
    padding-left: 0px !important;
}

.center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 1;
}